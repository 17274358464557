import Vue from 'vue';
import type { OnboardingState } from './OnboardingState';
import { defaultOnboardingData } from './OnboardingState';
import type {
  LoadingStatusPayload,
  GameProviderData,
  OperatorApiKeysData,
  OperatorIPAddress,
  OperatorOnboardingData,
  AccountOperator,
  NewAccountOperator,
  AccountInfo,
  TOnboardingProgress,
  IUserInfo,
  IInvitedUsers
} from '@/api/schema';
import {
  IInviteUserDialogOptions,
  ERole,
  TCopystakeIntegration
} from '@/api/schema';

/**
 * Mutations are the only way to actually change
 * state in the store and must be synchronous.
 * @category Onboarding
 */

export default {
  /**
   * Set loading status.
   * @param state Onboarding state.
   * @param payload Onboarding loading status for some key.
   */
  setLoadingState(state: OnboardingState, payload: LoadingStatusPayload): void {
    state.loading[payload.key] = payload.status;
  },

  /**
   * Set operator providers data.
   * @param state Onboarding.
   * @param data providers data.
   */
  setProviders(state: OnboardingState, data: GameProviderData[]): void {
    state.providers = data;
  },

  /**
   * Set operator api-keys data.
   * @param state Onboarding.
   * @param data api-keys data.
   */
  setApiKeys(state: OnboardingState, data: OperatorApiKeysData): void {
    Object.assign(state, {
      ...data
    });
  },

  /**
   * Set display status for provider modal.
   * @param state Onboarding state.
   * @param status provider modal display status.
   */
  setProviderDialogStatus(state: OnboardingState, status: boolean): void {
    state.displayProviderDialog = status;
  },

  /**
   * Set selected provider.
   * @param state Onboarding state.
   * @param provider provider data.
   */
  setSelectedProvider(
    state: OnboardingState,
    provider: GameProviderData
  ): void {
    state.selectedProvider = provider;
  },

  /**
   * Set Trueplay api's data.
   * @param state Onboarding.
   * @param data Trueplay api's data.
   */
  setTruePlayIPs(state: OnboardingState, data: string[]): void {
    state.truePlayIPs = data;
  },

  /**
   * Set Operator api's data.
   * @param state Onboarding.
   * @param data Operator api's data.
   */
  setOperatorIPs(state: OnboardingState, data: OperatorIPAddress[]): void {
    state.operatorIPs = data;
  },

  /**
   * Set Operator mode.
   * @param state Onboarding.
   * @param data Operator mode data.
   */
  setOperatorMode(
    state: OnboardingState,
    data: { [key: string]: string }
  ): void {
    state.mode = {
      ...state.mode,
      ...data
    };
  },

  /**
   * Set onboarding data.
   * @param state Onboarding.
   * @param data onboarding data.
   */
  setOnboardingData(
    state: OnboardingState,
    data: OperatorOnboardingData
  ): void {
    state.onboardingData = data;
  },

  /**
   * Set onboarding form data
   * @param state Onboarding state
   * @param data onboarding form
   */

  setOnboardingFormData(
    state: OnboardingState,
    data: Partial<OperatorOnboardingData>
  ): void {
    state.onboardingData = {
      ...state.onboardingData,
      ...data
    };
  },

  /**
   * Set account Operators data.
   * @param state Onboarding.
   * @param data Operator data.
   */
  setOperators(state: OnboardingState, data: AccountOperator[]): void {
    state.operators = data;
  },

  /**
   * Set display status for operator modal.
   * @param state Onboarding state.
   * @param status provider modal display status.
   */
  setOperatorDialogStatus(state: OnboardingState, status: boolean): void {
    state.displayOperatorDialog = status;
  },

  /**
   * Set operator dialog data.
   * @param state Onboarding state.
   * @param operator operator data.
   */
  setOperatorDialogData(
    state: OnboardingState,
    operator: AccountOperator
  ): void {
    state.operatorDialogData = operator;
  },

  /**
   * Set selected operator.
   * @param state Onboarding state.
   * @param operator operator data.
   */

  setSelectedOperator(state: OnboardingState, operator: AccountOperator): void {
    state.selectedOperator = operator;
  },

  /**
   * Set new operator data from modal.
   * @param state Onboarding state.
   * @param newOperator operator data.
   */

  setNewOperator(
    state: OnboardingState,
    newOperator: NewAccountOperator
  ): void {
    state.newOperator = newOperator;
  },

  /**
   * Reset Operators data.
   * @param state Onboarding state.
   */

  resetOperators(state: OnboardingState): void {
    state.onboardingData = { ...defaultOnboardingData };
    state.newOperator = {} as NewAccountOperator;
    state.selectedOperator = {} as AccountOperator;
    state.operators = [];
  },

  /**
   * Set account info
   * @param state Onboarding state
   * @param data object with account data
   */

  setAccountInfo(state: OnboardingState, data: AccountInfo | IUserInfo): void {
    state.accountInfo = data;
  },

  /**
   * Reset account info data
   * @param state Onboarding state
   */

  resetAccountInfo(state: OnboardingState): void {
    state.accountInfo = null;
  },

  setOnboardingProgress(
    state: OnboardingState,
    data: { operatorId: string; progress: TOnboardingProgress }
  ): void {
    Vue.set(state.onboardingProgress, data.operatorId, data.progress);
  },

  setInviteUserDialogOptions(
    state: OnboardingState,
    payload: IInviteUserDialogOptions = { show: false }
  ): void {
    state.inviteUserDialog = payload;
  },

  setInviteLinks(
    state: OnboardingState,
    payload: { role: ERole; link: string }[]
  ): void {
    state.inviteLinks = payload;
  },

  setTrafficProdProgress(
    state: OnboardingState,
    progress: TOnboardingProgress
  ): void {
    state.trafficProdProgress = progress;
  },

  setInviteUsers(state: OnboardingState, payload: IInvitedUsers[]): void {
    state.inviteUsers = payload;
  },

  setCopyStakeIntegrationData(
    state: OnboardingState,
    data: TCopystakeIntegration
  ): void {
    state.copyStakeIntegration = data;
  }
};
