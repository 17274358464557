import {
  defaultOnboardingData,
  defaultOperatorMode,
  OnboardingState
} from './OnboardingState';
import {
  getAllOperators,
  getApiKeys,
  getFormInfo,
  getOnboardingProgress,
  getOperatorIPAddresses,
  getOperatorMode,
  getProviders,
  getTruePlayIPAddresses,
  updateOnboardingProgress,
  saveActiveOperator,
  getInvitedUsers
} from '@/api/Onboarding';
import { getAccountOperators } from '@/api/Operator';
import { ERole } from '@/plugins/role-permissions/types';
import type { ActionContext } from 'vuex';
import type { AxiosError } from 'axios';
import {
  AccountOperator,
  EOnbordingStep,
  EOnbordingStepStatus,
  GameProviderData,
  OperatorApiKeysData,
  OperatorIPAddress,
  OperatorMode,
  OperatorOnboardingData,
  TCopystakeIntegration,
  TOnboardingPayloadStepType
} from '@/api/schema';
import { getInviteLinks } from '@/api/Auth';
import { isSuperAdmin } from '@/helpers/superAdminHelper';
import { getCopystakeIntegration } from '@/api/CopyStake/Integration';

const commonProgressSteps = [
  EOnbordingStep.tokenInfoProgress,
  EOnbordingStep.uiSettingsProgress,
  EOnbordingStep.buildCommunityProgress,
  EOnbordingStep.createAutoCommunicationProgress,
  EOnbordingStep.inviteMarketingManagerProgress,
  EOnbordingStep.launchLoyaltyPageProgress
];

let REQUEST_PROMISE = null;

/**
 * Actions are used to commit state mutations
 * and can contain asynchronous operations.
 * @category Onboarding
 */

export default {
  /**
   * Action used to get user onboarding form data.
   */
  getOnboardingFormData({
    commit
  }: ActionContext<OnboardingState, unknown>): void {
    commit('setLoadingState', { key: 'formData', status: true });
    getFormInfo()
      .then((data: OperatorOnboardingData) => {
        const {
          ggr,
          name,
          test,
          tokenName,
          tokenNumber,
          tokenPrice,
          tokenTicker,
          url
        } = data;

        commit('setOnboardingFormData', {
          ggr,
          name,
          test,
          tokenName,
          tokenNumber,
          tokenPrice,
          tokenTicker,
          url
        });
        commit('setOnboardingData', data);
        return data;
      })
      .catch((error: AxiosError) => {
        console.log(`Onboarding form data loading - `, error?.response?.data);
      })
      .then((data: OperatorOnboardingData): void => {
        const TokenSymbol = data?.tokenTicker ?? 'TOKEN';
        commit('Auth/setTokenSymbol', TokenSymbol, { root: true });
      })
      .finally((): void => {
        commit('setLoadingState', { key: 'formData', status: false });
      });
  },

  /**
   * Action used to reset user onboarding form data.
   */
  resetOnboardingFormData(
    { commit }: ActionContext<OnboardingState, unknown>,
    isProd: boolean = false
  ): void {
    commit('setOnboardingFormData', {
      ...defaultOnboardingData,
      test: !isProd
    });
    commit('setApiKeys', { proxyUrl: null, apiKey: null });
    commit('setOperatorMode', { ...defaultOperatorMode });
  },

  /**
   * Action used to get providers.
   */
  getProviders({ commit }: ActionContext<OnboardingState, unknown>): void {
    commit('setLoadingState', { key: 'providers', status: true });
    getProviders()
      .then((data: GameProviderData[]) => {
        commit('setProviders', data);
      })
      .catch((error: AxiosError) => {
        console.log(`Providers load error -`, error?.response?.data);
      })
      .finally((): void => {
        commit('setLoadingState', { key: 'providers', status: false });
      });
  },

  /**
   * Action used to get operator api keys.
   */
  getApiKeys({ commit }: ActionContext<OnboardingState, unknown>): void {
    commit('setLoadingState', { key: 'apiKeys', status: true });
    getApiKeys()
      .then((data: OperatorApiKeysData) => {
        commit('setApiKeys', data);
      })
      .catch((error: AxiosError) => {
        console.log(`Api-keys load error -`, error?.response?.data);
      })
      .finally((): void => {
        commit('setLoadingState', { key: 'apiKeys', status: false });
      });
  },

  /**
   * Action used to show provider dialog.
   */
  showProviderDialog(
    { commit }: ActionContext<OnboardingState, unknown>,
    data: GameProviderData
  ): void {
    commit('setSelectedProvider', data);
    commit('setProviderDialogStatus', true);
  },

  /**
   * Action used to hide provider dialog.
   */
  hideProviderDialog({
    commit
  }: ActionContext<OnboardingState, unknown>): void {
    commit('setProviderDialogStatus', false);
    commit('setSelectedProvider', {});
  },

  /**
   * Action used to get Trueplay IP's.
   */
  getTruePlayIPs({ commit }: ActionContext<OnboardingState, unknown>): void {
    commit('setLoadingState', { key: 'truePlayIPs', status: true });

    getTruePlayIPAddresses()
      .then((data: string[]) => {
        commit('setTruePlayIPs', data);
      })
      .catch((error: AxiosError) => {
        console.log(`Trueplay IP's load error -`, error?.response?.data);
      })
      .finally((): void => {
        commit('setLoadingState', { key: 'truePlayIPs', status: false });
      });
  },

  /**
   * Action used to get Operator IP's.
   */
  getOperatorIPs({
    commit
  }: ActionContext<OnboardingState, unknown>): Promise<void> {
    commit('setLoadingState', { key: 'operatorIPs', status: true });

    return getOperatorIPAddresses()
      .then((data: OperatorIPAddress[]) => {
        commit('setOperatorIPs', data);
      })
      .catch((error: AxiosError) => {
        console.log(`Operator IP's load error -`, error?.response?.data);
      })
      .finally((): void => {
        commit('setLoadingState', { key: 'operatorIPs', status: false });
      });
  },

  /**
   * Action used to get Operator mode.
   */
  getOperatorMode({ commit }: ActionContext<OnboardingState, unknown>): void {
    commit('setLoadingState', { key: 'mode', status: true });

    getOperatorMode()
      .then((data: OperatorMode) => {
        commit('setOperatorMode', data);
      })
      .catch((error: AxiosError) => {
        console.log(`Operator mode load error - `, error?.response?.data);
      })
      .finally((): void => {
        commit('setLoadingState', { key: 'mode', status: false });
      });
  },

  /**
   * Action used to get account operators.
   */
  getAllOperators(
    { state, commit, rootState }: ActionContext<OnboardingState, any>,
    update: boolean = false
  ): Promise<unknown> {
    commit('setLoadingState', { key: 'operators', status: true });
    if (state.operators.length && !update) return Promise.resolve();

    if (state.loading.operators && REQUEST_PROMISE) return REQUEST_PROMISE;

    const role = rootState.Auth.role;

    const accountRoles: ERole[] = [
      ERole.ROLE_ACCOUNT,
      ERole.ROLE_COPY_STAKE_ACCOUNT
    ];
    const isAccount: boolean = accountRoles.includes(role);
    const handler = isAccount ? getAllOperators : getAccountOperators;

    REQUEST_PROMISE = handler()
      .then((data: AccountOperator[]) => {
        commit('setOperators', data);
      })
      .catch((error: AxiosError) => {
        console.log(`Operators load error -`, error?.response?.data);
      })
      .finally((): void => {
        commit('setLoadingState', { key: 'operators', status: false });
        REQUEST_PROMISE = null;
      });

    return REQUEST_PROMISE;
  },

  /**
   * Action used to show operator dialog.
   */
  showOperatorDialog(
    { commit }: ActionContext<OnboardingState, unknown>,
    data: AccountOperator
  ): void {
    commit('setOperatorDialogData', data);
    commit('setOperatorDialogStatus', true);
  },

  /**
   * Action used to hide operator dialog.
   */
  hideOperatorDialog({
    commit
  }: ActionContext<OnboardingState, unknown>): void {
    commit('setOperatorDialogStatus', false);
  },

  async getOnboardingProgress(
    { commit, state, dispatch }: ActionContext<OnboardingState, unknown>,
    id: string | number
  ): Promise<void> {
    const operatorId = id || state.selectedOperator.id;

    if (!state.operators.length && !isSuperAdmin) {
      await dispatch('getAllOperators');
    }
    commit('setLoadingState', { key: 'progress', status: true });

    return getOnboardingProgress(operatorId)
      .then((data) => {
        commit('setOnboardingProgress', {
          operatorId,
          progress: data
        });
      })
      .finally(() =>
        commit('setLoadingState', { key: 'progress', status: false })
      );
  },

  async updateOnboardingProgress(
    { commit, state }: ActionContext<OnboardingState, unknown>,
    step: TOnboardingPayloadStepType
  ): Promise<void> {
    const currentId = state.selectedOperator.id;
    const currentProgress = state.onboardingProgress[currentId] || {};

    if (currentProgress[step] === EOnbordingStepStatus.COMPLETED) {
      return;
    }

    await updateOnboardingProgress(
      {
        ...currentProgress,
        [step]: EOnbordingStepStatus.COMPLETED
      },
      currentId
    ).then((data) =>
      commit('setOnboardingProgress', { operatorId: currentId, progress: data })
    );

    if (commonProgressSteps.includes(step)) {
      const currentId = state.selectedOperator.id;

      await Promise.allSettled(
        state.operators
          .filter(({ id }) => id !== currentId)
          .map(({ id }) =>
            getOnboardingProgress(id).then((currentProgress) =>
              updateOnboardingProgress(
                { ...currentProgress, [step]: EOnbordingStepStatus.COMPLETED },
                id
              )
            )
          )
      );
    }
  },

  async changeOperator(
    { commit, dispatch }: ActionContext<OnboardingState, unknown>,
    operator: AccountOperator
  ): Promise<void> {
    await saveActiveOperator(operator.id);
    await dispatch('Auth/setLastActiveOperator', operator.id, { root: true });
    commit('setSelectedOperator', operator);
  },

  async getInviteLinks(
    { getters, commit }: ActionContext<OnboardingState, unknown>,
    operators: number[]
  ): Promise<void> {
    return getInviteLinks({
      operators:
        operators || getters.currentAccount.children.map(({ id }) => id)
    }).then((data) => commit('setInviteLinks', data));
  },
  async getInviteUsers({
    commit
  }: ActionContext<OnboardingState, unknown>): Promise<void> {
    return getInvitedUsers().then((response) =>
      commit('setInviteUsers', response)
    );
  },

  async getCopyStakeIntegrationData({
    commit
  }: ActionContext<OnboardingState, unknown>): Promise<void> {
    commit('setLoadingState', { key: 'copyStakeIntegration', status: true });

    return getCopystakeIntegration()
      .then((data: TCopystakeIntegration) => {
        commit('setCopyStakeIntegrationData', data);
      })
      .catch((error: AxiosError) => {
        console.log(
          `Operator CopyStake integration data load error -`,
          error?.response?.data
        );
      })
      .finally((): void => {
        commit('setLoadingState', {
          key: 'copyStakeIntegration',
          status: false
        });
      });
  }
};
