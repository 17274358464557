import SecureLS from 'secure-ls';
import { getRole } from '@/helpers/tokenHelpers';

const ls = new SecureLS();
const token = ls.get('tokenKey');
const lastActiveOperator = ls.get('lastActiveOperator');

/**
 * The initial state for Authentication.
 * @category  Authentication
 */
export default {
  authStatus: '',
  token: token || '',
  role: getRole(token),
  tokenSymbol: null,
  accountId: null,
  lastActiveOperator: lastActiveOperator
};

/**
 * Describes Authentication state.
 * @category Authentication.
 */
export interface AuthState {
  authStatus: string;
  token: string;
  role: string | null;
  tokenSymbol: string;
  accountId: number | null;
  lastActiveOperator: number | null;
}
