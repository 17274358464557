import { getAccountId } from '@/helpers/tokenHelpers';
import { isSuperAdmin } from '@/helpers/superAdminHelper';
import { isGameProviderAdmin } from '@/helpers/gameProviderAdminHelper';
import {
  LoginAdmin,
  LoginSuperAdmin,
  LoginGameProviderAdmin
} from '@/api/Auth';
import SecureLS from 'secure-ls';
import type { ActionContext } from 'vuex';
import type { AuthState } from './AuthState';
import type { UserLoginRequestData } from '@/api/schema';
import { http } from '@/api/Connect';

const ls = new SecureLS();

/**
 * Actions are used to commit state mutations
 * and can contain asynchronous operations.
 * @category Authentification
 */

export default {
  /**
   * Action used to login.
   * @param vuex Action Context.
   * @param userData
   */
  async login(
    { commit, dispatch }: ActionContext<AuthState, unknown>,
    userData: UserLoginRequestData
  ): Promise<unknown> {
    commit('auth_request');
    const handler = isSuperAdmin
      ? LoginSuperAdmin
      : isGameProviderAdmin
      ? LoginGameProviderAdmin
      : LoginAdmin;
    return await handler(userData.email, userData.password)
      .then((response) => {
        dispatch('authorize', response.token);

        if (response.lastActiveOperator) {
          dispatch('setLastActiveOperator', response.lastActiveOperator);
        }
      })
      .catch((err) => {
        console.log('login error' + err);
        commit('auth_error');
        ls.remove('tokenKey');
        ls.remove('lastActiveOperator');
        return Promise.reject(err);
      });
  },

  /**
   * Action used to authorize user.
   * @param commit
   * @param token Authorization token.
   */
  authorize(
    { commit }: ActionContext<AuthState, unknown>,
    token: string
  ): void {
    commit('setAccountId', getAccountId(token));
    ls.set('tokenKey', token);
    http.setToken(token);
    commit('auth_success', token);
  },

  setLastActiveOperator(
    { commit }: ActionContext<AuthState, unknown>,
    id: number
  ): void {
    commit('setLastActiveOperator', id);
    ls.set('lastActiveOperator', id);
  },

  /**
   * Action used to logout.
   * @param vuex Action Context.
   */

  logout({ commit }: ActionContext<AuthState, unknown>): Promise<unknown> {
    return new Promise((resolve: any, reject: any): void => {
      commit('logout');
      commit('Onboarding/resetOperators', null, { root: true });
      commit('Onboarding/resetAccountInfo', null, { root: true });
      commit('OperatorPayments/setBalance', {}, { root: true });
      commit('Operator/resetAccountStorage', null, { root: true });
      ls.remove('tokenKey');
      http.removeToken();
      resolve().catch((error) => reject(error));
    });
  }
};
