import {
  AccountInfo,
  AccountOperator,
  ERole,
  GameProviderData,
  IInviteUserDialogOptions,
  IInvitedUsers,
  NewAccountOperator,
  OperatorBusinessType,
  OperatorIPAddress,
  OperatorMode,
  OperatorOnboardingData,
  TOnboardingProgress,
  TCopystakeIntegration
} from '@/api/schema';

export const defaultOnboardingData: OperatorOnboardingData = {
  ggr: '',
  name: '',
  test: true,
  tokenName: '',
  tokenNumber: 0,
  tokenPrice: 0,
  tokenTicker: '',
  url: '',
  businessType: OperatorBusinessType.casino,
  deployed: false,
  ip: [],
  proxy: '',
  status: '',
  liquidityPoolAddress: ''
};

export const defaultOperatorMode: OperatorMode = {
  url: null,
  proxyMode: null
};

/**
 * The initial state for Onboarding.
 * @category  Onboarding.
 */

export default {
  providers: [],
  onboardingData: { ...defaultOnboardingData },
  operators: [],
  newOperator: {},
  selectedOperator: {},
  operatorDialogData: {},
  loading: {
    formData: true,
    providers: false,
    apiKeys: false,
    truePlayIPs: false,
    operatorIPs: false,
    mode: false,
    operators: true,
    operatorDialog: false,
    progress: false,
    copyStakeIntegration: false
  },
  proxyUrl: null,
  apiKey: null,
  secret: null,
  integrationUrl: null,
  acceptProxyUrl: null,
  selectedProvider: {},
  displayProviderDialog: false,
  displayOperatorDialog: false,
  truePlayIPs: [],
  operatorIPs: [],
  mode: { ...defaultOperatorMode },
  accountInfo: null,
  inviteUserDialog: {
    title: undefined,
    description: undefined,
    list: [],
    show: false,
    closable: false
  },
  onboardingProgress: {},
  trafficProdProgress: null,
  inviteLinks: [],
  inviteUsers: [],
  copyStakeIntegration: {}
};

/**
 * Describes Onboarding state.
 * @category Onboarding.
 */
export interface OnboardingState {
  providers: GameProviderData[];
  onboardingData: OperatorOnboardingData;
  operators: AccountOperator[];
  newOperator: NewAccountOperator;
  selectedOperator: AccountOperator;
  operatorDialogData: AccountOperator;
  loading: {
    formData: boolean;
    providers: boolean;
    apiKeys: boolean;
    truePlayIPs: boolean;
    operatorIPs: boolean;
    mode: boolean;
    operators: boolean;
    operatorDialog: boolean;
    progress: boolean;
    copyStakeIntegration: boolean;
  };
  apiKey: string | null;
  proxyUrl: string | null;
  acceptProxyUrl: string | null;
  secret: string | null;
  integrationUrl: string | null;
  selectedProvider: GameProviderData;
  displayProviderDialog: boolean;
  displayOperatorDialog: boolean;
  truePlayIPs: string[];
  operatorIPs: OperatorIPAddress[];
  mode: OperatorMode;
  accountInfo: Partial<AccountInfo>;
  inviteUserDialog: IInviteUserDialogOptions;
  onboardingProgress: Record<string, TOnboardingProgress>;
  trafficProdProgress: TOnboardingProgress;
  inviteLinks: { role: ERole; link: string }[];
  inviteUsers: IInvitedUsers[];
  copyStakeIntegration: TCopystakeIntegration;
}
